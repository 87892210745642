import React from 'react';

const WistiaVideo = ({ vid }) => {
  return (
    <>
      <iframe
        width="560"
        height="315"
        src={`//fast.wistia.net/embed/iframe/${vid}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
      <script src="//fast.wistia.net/assets/external/E-v1.js" async></script>
    </>
  );
};

export default WistiaVideo;
