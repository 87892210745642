import { Link, graphql } from 'gatsby';
import React, { useMemo } from 'react';
import ArrowIcon from 'components/atoms/ArrowIcon';
import Layout from 'components/base/Layout';
import SEO from 'components/base/SEO';
import YoutubeVideo from 'components/atoms/YoutubeVideo';
import WistiaVideo from 'components/atoms/WistiaVideo';
import ResourceGrid from 'components/molecules/ResourceGrid';
import CtaSection from 'components/organisms/CtaSection';
import NewsletterCtaSection from 'components/organisms/NewsletterCtaSection';
import { md2react } from 'helpers/parser';

const VideoContent = ({ status, title, ytvid, wivid, heroImage, body }) => {
  const ytVideo = useMemo(() => (ytvid ? <YoutubeVideo vid={ytvid} /> : null), [
    ytvid,
  ]);

  const wiVid = useMemo(() => (wivid ? <WistiaVideo vid={wivid} /> : null), [
    wivid,
  ]);

  return (
    <>
      <div className={`bg-berry bg-opacity-15 pt-32 md:pt-48 pb-48 lg:pb-80`}>
        <div className="u-container px-6 lg:px-md flex flex-col items-center">
          {status === 'public' && (
            <Link
              className="inline-flex items-center text-berry font-semibold text-lg tracking-tighter transition hover:text-dark-grey focus:text-dark-grey"
              to="/videos"
            >
              <ArrowIcon className="transform rotate-180 mr-4 -mt-px" /> Back to
              Videos
            </Link>
          )}
          <h1 className="u-h1 mt-6 md:mt-8 mb-12 md:mb-20 text-center w-container max-w-full mx-auto">
            {title}
          </h1>
        </div>
      </div>

      <div className="u-container px-6 lg:px-md -mt-48 lg:-mt-80">
        {!ytvid && !wivid && heroImage && (
          <img src={heroImage} alt={title} className="w-full h-auto" />
        )}
        {!!ytvid && <div className="aspect-w-16 aspect-h-9">{ytVideo}</div>}
        {!!wivid && <div className="aspect-w-16 aspect-h-9">{wiVid}</div>}
      </div>

      <div className="u-section u-container px-6 lg:px-md">
        <div className="prose-xl lg:w-8/12">{md2react(body)}</div>
      </div>
    </>
  );
};

export const VideoPreview = ({ entry }) => {
  return <VideoContent {...entry.get('data').toJS()} />;
};

const VideoPage = ({
  data: {
    markdownRemark: { frontmatter: video, rawMarkdownBody },
    page: { frontmatter: page },
    related,
  },
}) => {
  const { status, seo, title, description } = video;

  const resources = related.edges.map(({ node }) => {
    const { title, slug, heroImage, description } = node.frontmatter || {};
    return {
      title,
      url: `/videos/${slug}`,
      thumbnailUrl: heroImage,
      type: 'video',
      excerpt: description,
    };
  });

  switch (status) {
    case 'unlisted':
      return (
        <Layout simple>
          <SEO
            title={seo?.title || title}
            description={seo?.description || description}
            openGraphImage={seo?.openGraphImage}
          />
          <VideoContent {...video} body={rawMarkdownBody} />
        </Layout>
      );
    default:
      return (
        <Layout>
          <SEO
            title={seo?.title || title}
            description={seo?.description || description}
            openGraphImage={seo?.openGraphImage}
          />

          <VideoContent {...video} body={rawMarkdownBody} />

          <NewsletterCtaSection
            headline={page?.newsletterCta?.headline}
            background="bg-berry text-white"
          />

          {resources.length > 0 && (
            <ResourceGrid
              lead="Related Videos"
              title="There’s always a lot going on at Skupos."
              cols={3}
              videoIconInverted
              resources={resources}
            />
          )}

          <CtaSection headline={page?.cta?.headline} />
        </Layout>
      );
  }
};

export const pageQuery = graphql`
  query VideoByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        status
        title
        description
        ytvid
        wivid
        heroImage
        seo {
          title
          description
          openGraphImage
        }
      }
      rawMarkdownBody
    }
    page: markdownRemark(fileAbsolutePath: { regex: "/pages/videos.md/" }) {
      frontmatter {
        newsletterCta {
          headline
        }
        cta {
          headline
        }
      }
    }
    related: allMarkdownRemark(
      filter: {
        id: { ne: $id }
        frontmatter: { templateKey: { eq: "video" }, status: { eq: "public" } }
      }
      sort: { fields: [frontmatter___date], order: [DESC] }
      limit: 3
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
            heroImage
            description
          }
        }
      }
    }
  }
`;

export default VideoPage;
